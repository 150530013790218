import React, { Component, Fragment } from "react";
import { Box, Button, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import Logo from "../../components/UI/Logo/Logo";
import classes from "./Layout.module.css";

// import { postErrorLogs } from "../../store/state/api/Utility";

class ErrorBoundary extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error | string | null) {
    return { hasError: true, error: null };
  }

  componentDidCatch(
    error: Error | string | null,
    info: { componentStack: any }
  ) {
    //TODO uncomment
    // postErrorLogs({
    //   error: error?.toString() ?? "",
    //   stack: info.componentStack.toString(),
    // }).catch((err: Error) => {
    //   console.error(err);
    // });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Fragment>
          <Box sx={ { padding: (theme) => theme.spacing(2) } }>
            <Logo
              className={ [classes.Image, classes.DisplayCenter].join(" ") }
            />
            <Typography variant={ "h2" } textAlign={ "center" }>
              Whoops, Something Went Wrong.
            </Typography>
            <Typography variant={ "h4" } textAlign={ "center" }>
              Please return home and try again.
              <br />
              If the issue continues, please get in touch.
            </Typography>
            <Box sx={ { textAlign: "center" } }>
              <NavLink
                to={ "/" }
                onClick={ () => {
                  this.setState({ hasError: false, error: null });
                } }
              >
                <Button variant={ "outlined" } sx={ { mx: "auto" } }>
                  Go Home
                </Button>
              </NavLink>
            </Box>
          </Box>
        </Fragment>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
