import { GetWrapper } from "../../../axios";
import { IAverageSale, IDetailedSale, IRunningReport, IStockSalesReport } from "../../../models/Report";

export const getAverageSalesReport = (params?: any) => GetWrapper<IAverageSale>("/Reports/HourlySummary", {
  params: params
});

export const getDetailedReport = (params?: any) => GetWrapper<Array<IDetailedSale>>("/Reports/AllSales", {
  params: params
});

export const getRunningReport = (params?: any) => GetWrapper<Array<IRunningReport>>("/Reports/Summary", {
  params: params
});

export const getStockSalesReport = (params?: any) => GetWrapper<Array<IStockSalesReport>>("/Reports/StockSales", {
  params: params
});