import React from "react";

import classes from "./Spinner.module.css";
import Logo from "../../UI/Logo/Logo";

const Spinner = () =>
  (
    <div className={ classes.Spinner }>
      <Logo className={ classes.Logo } />
    </div>
  );

export default React.memo(Spinner);
