import { User, WebStorageStateStore } from "oidc-client-ts";
import { AuthProviderProps } from "react-oidc-context";

export const onSigninCallback = (_user: User | void): void => {

  window.history.replaceState(
    {},
    document.title,
    window.location.origin + window.location.pathname
  );
};

let settings: AuthProviderProps = {
  authority: "https://keycloak.barclaysonline.co.za/realms/apron-strings",
  client_id: "POS",
  response_type: "code",
  scope: "openid email profile",
  redirect_uri: window.location.origin + window.location.pathname,
  onSigninCallback: onSigninCallback,
  post_logout_redirect_uri: window.location.origin,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  client_secret: "zp0onBArO3xIGVxykhyowomF0GXwLjva",
  userStore: new WebStorageStateStore({ store: window.localStorage })
};

const setSettings: (newSettings?: Partial<AuthProviderProps>) => void = (
  newSettings
) => {
  if (newSettings) {
    // @ts-ignore
    settings = { ...settings, ...newSettings };
  }
};

const getSettings: () => AuthProviderProps = () => settings;

export { getSettings, setSettings };
