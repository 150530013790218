import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../Store";
import { ICartMenuItem } from "../../../models";

export interface ICartState {
  cart: Array<ICartMenuItem>,
  checkout: boolean;
}

const getInitialState: () => ICartState = () => {
  let initialState: ICartState = {
    cart: [],
    checkout: false
  };

  return initialState;
};

export const CartSlice = createSlice({
  name: "Cart",
  initialState: getInitialState(),
  reducers: {
    AddToCart: (state, params: PayloadAction<ICartMenuItem>) => {
      const itemIndex = state.cart.findIndex(item => item.id === params.payload.id);
      if (itemIndex > -1) {
        // Found Item In Cart
        const tempCart = state.cart;
        const temp = tempCart[itemIndex];
        temp.quantity += 1;
        tempCart[itemIndex] = temp;
        state.cart = tempCart;
      } else {
        state.cart = [...state.cart, params.payload];
      }
    },
    RemoveFromCart: (state, action: PayloadAction<{ id: number }>) => {
      const itemIndex = state.cart.findIndex(item => item.id === action.payload.id);
      if (itemIndex > -1) {
        // Found Item In Cart
        const tempCart = state.cart;
        const temp = tempCart[itemIndex];
        temp.quantity -= 1;
        if (temp.quantity === 0) {
          tempCart.splice(itemIndex, 1);
        } else {
          tempCart[itemIndex] = temp;
        }
        state.cart = tempCart;
      }
    },
    ClearCart: (state) => {
      state.cart = [];
    },
    SetCheckoutActive: (state) => {
      state.checkout = true;
    },
    SetCheckoutInactive: (state) => {
      state.checkout = false;
    }
  },
  extraReducers: (builder) => {

  },
});

export const APIStateCart = (
  builder: ActionReducerMapBuilder<any>
) => {

};

export const CartState = (state: RootState) =>
  state.CartState;

export default CartSlice.reducer;
