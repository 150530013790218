import {
  ActionReducerMapBuilder, createAsyncThunk, SerializedError,
} from "@reduxjs/toolkit";

import { addCases } from "./APIState";
import { deleteMenuItem, postMenuItem, putActivateDeactivateMenuItem, putUpdateMenuItem } from "./api/Menu";
import { IAutoComplete, IMenuItem, INewContactPerson, ISale } from "../../models";
import { postMakeSale, postResendReceipt, putUpdateContacts } from "./api/Sale";
import { postNewClient } from "./api/ContactPerson";

export const putActivateDeactivateMenuItemAsync = createAsyncThunk(
  "Menu/putActivateDeactivateMenuItem",
  async (params: { id: number, active: boolean }) => await putActivateDeactivateMenuItem(params.id, params.active),
  {
    serializeError: (error: unknown): SerializedError =>
      error as SerializedError,
  }
);

export const deleteMenuItemAsync = createAsyncThunk(
  "Menu/deleteMenuItem",
  async (id: number) => await deleteMenuItem(id),
  {
    serializeError: (error: unknown): SerializedError =>
      error as SerializedError,
  }
);

export const putUpdateMenuItemAsync = createAsyncThunk(
  "Menu/putUpdateMenuItem",
  async (menuItem: IMenuItem) => await putUpdateMenuItem(menuItem),
  {
    serializeError: (error: unknown): SerializedError =>
      error as SerializedError,
  }
);

export const postMenuItemAsync = createAsyncThunk(
  "Menu/postMenuItem",
  async (menuItem: IMenuItem) => await postMenuItem(menuItem),
  {
    serializeError: (error: unknown): SerializedError =>
      error as SerializedError,
  }
);

export const postMakeSaleAsync = createAsyncThunk(
  "Sale/postMakeSale",
  async (data: ISale) => await postMakeSale(data),
  {
    serializeError: (error: unknown): SerializedError =>
      error as SerializedError,
  }
);

export const postNewClientAsync = createAsyncThunk(
  "ContactPerson/postNewClient",
  async (data: INewContactPerson) => await postNewClient(data),
  {
    serializeError: (error: unknown): SerializedError =>
      error as SerializedError,
  }
);

export const putUpdateContactsAsync = createAsyncThunk(
  "Sale/putUpdateContacts",
  async (data: Array<IAutoComplete>) => await putUpdateContacts(data),
  {
    serializeError: (error: unknown): SerializedError =>
      error as SerializedError,
  }
);


export const postResendReceiptAsync = createAsyncThunk(
  "Sale/postResendReceipt",
  async (data: { saleID: number }) => await postResendReceipt(data),
  {
    serializeError: (error: unknown): SerializedError =>
      error as SerializedError,
  }
);


export const APIStateAsyncThunks = (builder: ActionReducerMapBuilder<any>) => {
  addCases(putActivateDeactivateMenuItemAsync, builder, {
    name: "putActivateDeactivateMenuItems",
    rejected: {
      message: "Failed To Change Active Status",
    },
    fulfilled: {
      message: "Successfully Changed Active Status",
    },
    pending: {
      status: "updating",
    },
  });
  addCases(deleteMenuItemAsync, builder, {
    name: "deleteMenuItem",
    rejected: {
      message: "Failed To Delete Menu Item",
    },
    fulfilled: {
      message: "Successfully Deleted Menu Item",
    },
    pending: {
      status: "deleting",
    },
  });
  addCases(putUpdateMenuItemAsync, builder, {
    name: "putUpdateMenuItem",
    rejected: {
      message: "Failed To Update Menu Item",
    },
    fulfilled: {
      message: "Successfully Updated Menu Item",
    },
    pending: {
      status: "updating",
    },
  });
  addCases(postMenuItemAsync, builder, {
    name: "postMenuItem",
    rejected: {
      message: "Failed To Create New Menu Item",
    },
    fulfilled: {
      message: "Successfully Created New Menu Item",
    },
    pending: {
      status: "creating",
    },
  });
  addCases(postMakeSaleAsync, builder, {
    name: "postMakeSale",
    rejected: {
      message: "Failed To Complete Sale",
    },
    fulfilled: {
      message: "Successfully Captured Sale",
    },
    pending: {
      status: "creating",
    },
  });
  addCases(postNewClientAsync, builder, {
    name: "postNewClient",
    rejected: {
      message: "Failed To Save New Contact",
    },
    fulfilled: {
      message: "Successfully Saved New Contact",
    },
    pending: {
      status: "creating",
    },
  });
  addCases(postResendReceiptAsync, builder, {
    name: "postResendReceipt",
    rejected: {
      message: "Failed To Resend Receipt",
    },
    fulfilled: {
      message: "Successfully Resent Receipt",
    },
    pending: {
      status: "updating",
    },
  });

};

