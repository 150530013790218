import React, { useEffect, useState } from "react";
import ClientPortal from "./container/POSPortal";
import Layout from "./hoc/Layout/Layout";
import packageJson from "../package.json";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { getTheme } from "./themeJSON";
import { hasAuthParams, useAuth } from "react-oidc-context";
import Spinner from "./components/UI/Spinner/Spinner";

const _global = ( window || global ) as any;
_global.appVersion = packageJson.version;

const App = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasTriedSignin, setHasTriedSignin] = useState<boolean>(false);
  // @ts-ignore
  const theme = createTheme(getTheme());
  const auth = useAuth();

  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
      setLoading(true);
      auth.signinSilent().finally(() => setLoading(false));
      setHasTriedSignin(true);
    }
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect, auth, hasTriedSignin]);

  useEffect(() => {

    return auth.events.addAccessTokenExpired(() => {
      console.debug("All Tokens Revoked");
      auth.startSilentRenew();
    });
  }, [auth, auth.events]);

  useEffect(() => {
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent().then(() => console.debug("New Sign In"));
    });
  }, [auth.events, auth.signinSilent, auth]);

  return (
    <ThemeProvider theme={ theme }>
      <CssBaseline />
      <Layout links={ [
        {
          value: "Sales",
          link: "/",
          internal: true,
        },
        {
          value: "Manage Menu",
          link: "/Manage-Menu-Items",
          internal: true,
        },
        {
          value: "Reports",
          link: "/Reports",
          internal: true,
          children: [
            {
              value: "Average Sales Report",
              link: "/Reports/Average-Sales",
              internal: true,
            },
            {
              value: "Detailed Report",
              link: "/Reports/Detailed",
              internal: true,
            },
            {
              value: "Running Report",
              link: "/Reports/Running",
              internal: true,
            },
            {
              value: "Stock Sales Report",
              link: "/Reports/Stock-Sales",
              internal: true,
            },
          ]
        },
      ] }>
        <div id={ "Modal" } />
        { loading ? <Spinner /> : <ClientPortal /> }
      </Layout>
    </ThemeProvider>
  );
};

export default ( App );
