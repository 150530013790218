import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { RootState } from "../Store";
import { addCases } from "../APIState";
import { IContactPerson } from "../../../models";
import { getClients } from "../api/ContactPerson";

export interface IContactPersonState {
  contactPerson: Array<IContactPerson>;
}

const getInitialState: () => IContactPersonState = () => {
  let initialState: IContactPersonState = {
    contactPerson: []
  };

  return initialState;
};

export const getClientsAsync = createAsyncThunk(
  "ContactPerson/getClients",
  async () => await getClients(),
  {
    serializeError: (error: unknown): SerializedError =>
      error as SerializedError,
  }
);

export const ContactPersonSlice = createSlice({
  name: "ContactPerson",
  initialState: getInitialState(),
  reducers: {
    Clear: (state, params: PayloadAction<{ name: string }>) => {
      const { name } = params.payload;
      // @ts-ignore
      state[name] = null;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getClientsAsync.pending, (state) => {
        state.contactPerson = [];
      })
      .addCase(getClientsAsync.fulfilled, (state, action) => {
        if (action.payload.metaData.success && action.payload.data) {
          state.contactPerson = action.payload.data;
        }
      });

  },
});

export const APIStateContactPerson = (
  builder: ActionReducerMapBuilder<any>
) => {

  addCases(getClientsAsync, builder, {
    name: "getClients",
    rejected: {
      message: "Failed To Get Contacts",
    },
    fulfilled: {
      message: "Successfully Fetched Contacts",
    },
    pending: {
      status: "fetching",
    },
  });

};

export const ContactPersonState = (state: RootState) =>
  state.ContactPersonState;

export default ContactPersonSlice.reducer;
