import {
  IMenuItem, IMenuItemCategory, ISingleMenuItem,
} from "../../../models";
import { DeleteWrapper, GetWrapper, PostWrapper, PutWrapper } from "../../../axios";

export const getMenuItems = () =>
  GetWrapper<Array<IMenuItem>>("/Menu/MenuItems");

export const getMenuCategories = () => GetWrapper<Array<IMenuItemCategory>>("/Menu/MenuCategories");

export const getManageMenu = (categoryID: number) => GetWrapper<Array<ISingleMenuItem>>(`/Menu/ManageMenuItems/${ categoryID }`);

export const putActivateDeactivateMenuItem = (id: number, active: boolean) => PutWrapper<null>(`/Menu/ActivateDeactivate/${ id }`, { active });

export const deleteMenuItem = (id: number) => DeleteWrapper<null>(`/Menu/${ id }`);

export const putUpdateMenuItem = (menuItem: IMenuItem) => PutWrapper<null>("/Menu/ManageMenuItem", menuItem);

export const postMenuItem = (menuItem: IMenuItem) => PostWrapper<null>("/Menu/", menuItem);
