import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import { ILinkInterface } from "../../../models";

interface IF_ChildMenu {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: any;
  closeSideDrawer: Function;
  link: ILinkInterface;
}

const ChildMenu = ({
                     anchorEl,
                     open,
                     handleClose,
                     closeSideDrawer,
                     link,
                   }: IF_ChildMenu) => {
  return (
    <Menu
      anchorEl={ anchorEl }
      open={ open }
      onClose={ handleClose }
      MenuListProps={ {
        "aria-labelledby": "basic-button",
      } }
    >
      <MenuItem
        //@ts-ignore
        to={ link.link }
        component={ Link }
        onClick={ (e: any) => {
          handleClose();
          closeSideDrawer(e);
        } }
      >
        { link.value }
      </MenuItem>
      { link.children?.map((l) => (
        <MenuItem
          onClick={ (e: any) => {
            handleClose();
            closeSideDrawer(e);
          } }
          key={ `${ l.link }_menu_item` }
          //@ts-ignore
          to={ l.link }
          component={ Link }
        >
          { l.value }
        </MenuItem>
      )) }
    </Menu>
  );
};

export default ChildMenu;
