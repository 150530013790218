import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import LoginForm from "../../components/AuthenticationComponents/LoginForm/LoginForm";
import { useAuth } from "react-oidc-context";

interface IProtectedRoute {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: IProtectedRoute) => {
  const location = useLocation();
  const auth = useAuth();

  return (
    <React.Fragment>
      { auth.isAuthenticated ? children : <LoginForm redirectURL={ location.pathname } /> }
    </React.Fragment>
  );
};

export default ( ProtectedRoute );
