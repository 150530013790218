import React from "react";

import { Route, Routes } from "react-router-dom";

import LazyLoader from "../hoc/LazyLoader/LazyLoader";
import ProtectedRoute from "../hoc/ProtectedRoute/ProtectedRoute";

const LoginLazy = LazyLoader(() => import("../components/AuthenticationComponents/LoginForm/LoginForm"));

const SalesHomeLazy = LazyLoader(() => import("../components/HomeComponents/SalesHomePage/SalesHomePage"));

const ManageMenuLazy = LazyLoader(() => import("../components/HomeComponents/ManageMenu/ManageMenu"));

const ReportsLazy = LazyLoader(() => import("../components/ReportsComponents/Reports"));

const POSPortal = () => (
  <Routes>
    <Route path={ "/Manage-Menu-Items" } element={ <ProtectedRoute><ManageMenuLazy /></ProtectedRoute> } />
    <Route path={ "/Login" } element={ <LoginLazy /> } />
    <Route path={ "/Reports/*" } element={ <ProtectedRoute>
      <ReportsLazy />
    </ProtectedRoute> } />
    <Route path={ "/*" } element={ <ProtectedRoute>
      <SalesHomeLazy />
    </ProtectedRoute> } />
  </Routes>
);


export default ( POSPortal );
