import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { RootState } from "../Store";
import { addCases } from "../APIState";
import { getMenuItems, getMenuCategories, getManageMenu } from "../api/Menu";
import { IMenuItem, IMenuItemCategory, ISingleMenuItem } from "../../../models";

export interface IMenuState {
  menuItems: Array<IMenuItem>;
  menuCategories: Array<IMenuItemCategory>;
  activeMenuCategories: Array<IMenuItemCategory>;
  manageMenuItems: Array<ISingleMenuItem>;
}

const getInitialState: () => IMenuState = () => {
  let initialState: IMenuState = {
    menuItems: [],
    menuCategories: [],
    manageMenuItems: [],
    activeMenuCategories: []
  };

  return initialState;
};

export const getMenuItemsAsync = createAsyncThunk(
  "Menu/getMenuItems",
  async () => await getMenuItems(),
  {
    serializeError: (error: unknown): SerializedError =>
      error as SerializedError,
  }
);

export const getManageMenuAsync = createAsyncThunk(
  "Menu/getManageMenu",
  async (categoryID: number) => await getManageMenu(categoryID),
  {
    serializeError: (error: unknown): SerializedError =>
      error as SerializedError,
  }
);

export const getMenuCategoriesAsync = createAsyncThunk(
  "Menu/getMenuCategories",
  async () => await getMenuCategories(),
  {
    serializeError: (error: unknown): SerializedError =>
      error as SerializedError,
  }
);

export const MenuSlice = createSlice({
  name: "Menu",
  initialState: getInitialState(),
  reducers: {
    Clear: (state, params: PayloadAction<{ name: string }>) => {
      const { name } = params.payload;
      // @ts-ignore
      state[name] = null;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMenuCategoriesAsync.pending, (state) => {
      state.menuCategories = [];
    })
      .addCase(getMenuCategoriesAsync.fulfilled, (state, action) => {
        if (action.payload.metaData.success && action.payload.data) {
          state.menuCategories = action.payload.data;
        }

        if (state.menuItems.length > 0) {
          // @ts-ignore
          state.activeMenuCategories = state.menuCategories.map(
            (category, index) =>
              state.menuItems.filter(item => item.typeID === category.id).length > 0 ? category : null)
            .filter(e => e);
        }
      })
      .addCase(getMenuItemsAsync.pending, (state) => {
        state.menuItems = [];
      })
      .addCase(getMenuItemsAsync.fulfilled, (state, action) => {
        if (action.payload.metaData.success && action.payload.data) {
          state.menuItems = action.payload.data;
        }

        if (state.menuCategories.length > 0) {
          // @ts-ignore
          state.activeMenuCategories = state.menuCategories.map(
            (category, index) =>
              state.menuItems.filter(item => item.typeID === category.id).length > 0 ? category : null)
            .filter(e => e);
        }
      })
      .addCase(getManageMenuAsync.pending, (state) => {
        state.manageMenuItems = [];
      })
      .addCase(getManageMenuAsync.fulfilled, (state, action) => {
        if (action.payload.metaData.success && action.payload.data) {
          state.manageMenuItems = action.payload.data;
        }
      });

  },
});

export const APIStateMenu = (
  builder: ActionReducerMapBuilder<any>
) => {

  addCases(getMenuCategoriesAsync, builder, {
    name: "getMenuCategories",
    rejected: {
      message: "Failed To Get Menu Categories",
    },
    fulfilled: {
      message: "Successfully Fetched Categories",
    },
    pending: {
      status: "fetching",
    },
  });

  addCases(getMenuItemsAsync, builder, {
    name: "getMenuItems",
    rejected: {
      message: "Failed To Fetch Menu Items",
    },
    fulfilled: {
      message: "Successfully Fetched Menu Items",
    },
    pending: {
      status: "fetching",
    },
  });
};

export const MenuState = (state: RootState) =>
  state.MenuState;

export default MenuSlice.reducer;
