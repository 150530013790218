import { configureStore } from "@reduxjs/toolkit";
import { APISlice } from "./APIState";
import logger from "redux-logger";
import { combineReducers } from "redux";
import { MenuSlice } from "./slices/Menu";
import { CartSlice } from "./slices/Cart";
import { ContactPersonSlice } from "./slices/ContactPerson";
import { ReportSlice } from "./slices/Reports";

const reducer = combineReducers({
  APIStates: APISlice.reducer,
  CartState: CartSlice.reducer,
  ContactPersonState: ContactPersonSlice.reducer,
  MenuState: MenuSlice.reducer,
  ReportState: ReportSlice.reducer
});

export const Store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => {
    return process.env.NODE_ENV !== "production"
      ? getDefaultMiddleware({
        serializableCheck: false,
      }).concat(logger)
      : getDefaultMiddleware({
        serializableCheck: false,
      });
  },
  devTools: process.env.NODE_ENV !== "production",
});

export type Dispatch = typeof Store.dispatch;
export type RootState = ReturnType<typeof Store.getState>;
