import React, { useEffect, useState } from "react";
import { format } from "date-fns";

import classes from "./Clock.module.css";
import { enZA } from "date-fns/locale";

const dateFormat = "iiii, dd LLLL yyyy";

const Clock = () => {

  const [time, setTime] = useState(new Date().toLocaleTimeString("en-za"));
  const [date, setDate] = useState(format(new Date(), dateFormat, { locale: enZA }));

  useEffect(() => {
    const timeUpdate = setInterval(() => {
      if (format(new Date(), dateFormat, { locale: enZA }) !== date) setDate(format(new Date(), dateFormat, { locale: enZA }));
      setTime(new Date().toLocaleTimeString("en-za"));
    }, 1000);

    return () => clearInterval(timeUpdate);
  }, [date]);

  return ( <div className={ [classes.Wrapper, "MediumPadding-Top", "MediumPadding-Right"].join(" ") }>
    <span className={ classes.Clock }>{ date }</span>
    <span className={ classes.Clock }>{ time }</span>
    <span className={ classes.Clock }>Paramount Canteen</span>
  </div> );
};

export default Clock;
