import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Store } from "./store/state/Store";
import ContextProvider from "./services/ContextProvider";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import { getSettings } from "./auth";
import { AuthProvider } from "react-oidc-context";

const configuration = {
  onUpdate: (registration: any) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
    }
  },
};

// @ts-ignore
const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter basename="/">
    <ContextProvider>
      <Provider store={ Store }>
        <AuthProvider { ...getSettings() }>
          <App />
        </AuthProvider>
        <ToastContainer
          position={ toast.POSITION.BOTTOM_CENTER }
          limit={ 3 }
          hideProgressBar={ true }
          newestOnTop={ true }
          closeOnClick={ true }
          rtl={ false }
          pauseOnFocusLoss={ true }
          draggable={ false }
          pauseOnHover={ true }
        />
      </Provider>
    </ContextProvider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(configuration);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
