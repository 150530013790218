import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { RootState } from "../Store";
import { addCases } from "../APIState";
import { getAverageSalesReport, getDetailedReport, getRunningReport, getStockSalesReport } from "../api/Reports";
import { IAverageSale, IDetailedSale, IRunningReport, IStockSalesReport } from "../../../models/Report";

export interface IReportState {
  averageSales: IAverageSale | null;
  detailedSales: Array<IDetailedSale>;
  detailedSalesBreakDown: {
    cashSalesTaken: number | null;
    cardSalesTaken: number | null;
    onCredit: number | null;
  };
  runningReport: Array<IRunningReport>;
  stockSales: Array<IStockSalesReport>;
}

const getInitialState: () => IReportState = () => {
  let initialState: IReportState = {
    averageSales: null,
    detailedSales: [],
    detailedSalesBreakDown: {
      cashSalesTaken: null,
      cardSalesTaken: null,
      onCredit: null
    },
    runningReport: [],
    stockSales: [],
  };

  return initialState;
};

export const getAverageSalesAsync = createAsyncThunk(
  "Report/getAverageSales",
  async (params?: any) => await getAverageSalesReport(params),
  {
    serializeError: (error: unknown): SerializedError =>
      error as SerializedError,
  }
);

export const getDetailedReportAsync = createAsyncThunk(
  "Report/getDetailedReport",
  async (params?: any) => await getDetailedReport(params),
  {
    serializeError: (error: unknown): SerializedError =>
      error as SerializedError,
  }
);

export const getRunningReportAsync = createAsyncThunk(
  "Report/getRunningReport",
  async (params?: any) => await getRunningReport(params),
  {
    serializeError: (error: unknown): SerializedError =>
      error as SerializedError,
  }
);

export const getStockSalesReportAsync = createAsyncThunk(
  "Report/getStockSalesReport",
  async (params?: any) => await getStockSalesReport(params),
  {
    serializeError: (error: unknown): SerializedError =>
      error as SerializedError,
  }
);

export const ReportSlice = createSlice({
  name: "Report",
  initialState: getInitialState(),
  reducers: {
    Clear: (state, params: PayloadAction<{ name: string }>) => {
      const { name } = params.payload;
      // @ts-ignore
      state[name] = null;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAverageSalesAsync.pending, (state) => {
        state.averageSales = null;
      })
      .addCase(getAverageSalesAsync.fulfilled, (state, action) => {
        if (action.payload.metaData.success && action.payload.data) {
          state.averageSales = action.payload.data;
        }
      })
      .addCase(getDetailedReportAsync.pending, (state) => {
        state.detailedSales = [];
      })
      .addCase(getDetailedReportAsync.fulfilled, (state, action) => {
        if (action.payload.metaData.success && action.payload.data) {
          state.detailedSales = action.payload.data;

          const GetSaleAmountTendered = (data: Array<IDetailedSale>, searchName: string) => {
            return data.reduce((acc, cur) => {
              cur.transactions.forEach(t => {
                if (t.paymentMethod === searchName) {
                  acc += +t.amountTendered;
                }
              });
              return acc;
            }, 0);
          };

          state.detailedSalesBreakDown.cashSalesTaken = GetSaleAmountTendered(action.payload.data, "Cash");
          state.detailedSalesBreakDown.cardSalesTaken = GetSaleAmountTendered(action.payload.data, "Credit/Debit Card");
          state.detailedSalesBreakDown.onCredit = GetSaleAmountTendered(action.payload.data, "On Credit");

        }
      })
      .addCase(getRunningReportAsync.pending, (state) => {
        state.runningReport = [];
      })
      .addCase(getRunningReportAsync.fulfilled, (state, action) => {
        if (action.payload.metaData.success && action.payload.data) {
          state.runningReport = action.payload.data;
        }
      })
      .addCase(getStockSalesReportAsync.pending, (state) => {
        state.stockSales = [];
      })
      .addCase(getStockSalesReportAsync.fulfilled, (state, action) => {
        if (action.payload.metaData.success && action.payload.data) {
          state.stockSales = action.payload.data;
        }
      });
  },
});

export const APIStateReport = (
  builder: ActionReducerMapBuilder<any>
) => {
  addCases(getAverageSalesAsync, builder, {
    name: "getAverageSales",
    rejected: {
      message: "Failed To Get Average Sales Report",
    },
    fulfilled: {
      message: "Successfully Fetched Average Sales Report",
    },
    pending: {
      status: "fetching",
    },
  });
  addCases(getDetailedReportAsync, builder, {
    name: "getDetailedReport",
    rejected: {
      message: "Failed To Get Detailed Sales Report",
    },
    fulfilled: {
      message: "Successfully Fetched Detailed Sales Report",
    },
    pending: {
      status: "fetching",
    },
  });
  addCases(getRunningReportAsync, builder, {
    name: "getRunningReport",
    rejected: {
      message: "Failed To Get Running Sales Report",
    },
    fulfilled: {
      message: "Successfully Fetched Running Sales Report",
    },
    pending: {
      status: "fetching",
    },
  });
  addCases(getStockSalesReportAsync, builder, {
    name: "getStockSalesReport",
    rejected: {
      message: "Failed To Get Stock Sales Report",
    },
    fulfilled: {
      message: "Successfully Fetched Stock Sales Report",
    },
    pending: {
      status: "fetching",
    },
  });
};

export const ReportState = (state: RootState) =>
  state.ReportState;

export default ReportSlice.reducer;
