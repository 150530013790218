import {
  ActionReducerMapBuilder,
  AsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import { RootState } from "./Store";
import { toast } from "react-toastify";
import { APIStateAsyncThunks } from "./AsyncThunks";
import { APIStateContactPerson } from "./slices/ContactPerson";
import { APIStateCart } from "./slices/Cart";
import { APIStateMenu } from "./slices/Menu";
import { APIStateReport } from "./slices/Reports";

export interface IF_APIState {
  [name: string]: {
    status: "creating" | "fetching" | "updating" | "deleting" | "idle";
    error: boolean;
    message: {
      severity: "info" | "warning" | "error" | "success";
      message: string | null;
    } | null;
    // pagination?: Pagination;
  };
}

export const addCases = <T>(
  caseType: AsyncThunk<T, any, {}>,
  builder: ActionReducerMapBuilder<any>,
  messageObject: {
    name: string;
    pending: {
      status: "creating" | "fetching" | "updating" | "deleting" | "idle";
    };
    fulfilled: { message: string | null };
    rejected: { message: string };
    // pagination?: Pagination;
  }
) => {
  builder
    .addCase(caseType.pending, (state) => {
      state[messageObject.name] = {
        status: messageObject.pending.status,
        error: false,
        message: null,
      };
    })
    .addCase(caseType.fulfilled, (state, action?) => {
      toast(
        `${
          // @ts-ignore
          action.payload?.metaData?.message ?? messageObject.fulfilled.message
        }`,
        {
          autoClose: 2000,
          type: toast.TYPE.SUCCESS,
          theme: "dark",
          toastId:
          // @ts-ignore
            action.payload?.metaData?.message ??
            messageObject.fulfilled.message,
        }
      );
      state[messageObject.name] = {
        status: "idle",
        error: false,
        message: {
          severity: "success",
          message:
          // @ts-ignore
            action.payload?.metaData?.message ??
            messageObject.fulfilled.message,
        },
      };
    })
    .addCase(caseType.rejected, (state, action?) => {
      toast(
        `${
          // @ts-ignore
          action.error.message ?? messageObject.fulfilled.message
        }`,
        {
          autoClose: 2000,
          type: toast.TYPE.ERROR,
          theme: "dark",
          // @ts-ignore
          toastId: action.error.message ?? messageObject.fulfilled.message,
        }
      );
      state[messageObject.name] = {
        status: "idle",
        error: true,
        message: {
          severity: "error",
          message: `${ action.error.code ? `${ action.error.code }: ` : "" }${
            action.error.message?.toLowerCase() === "internal server error!"
              ? messageObject.rejected.message
              : action.error.message
          }`,
        },
      };
    });
  return builder;
};
const initialState: IF_APIState = {};

export const APISlice = createSlice({
  name: "APIState",
  initialState,
  reducers: {
    Add: (state, params: PayloadAction<{ name: string }>) => {
      const { name } = params.payload;
      state[name] = {
        status: "idle",
        error: false,
        message: null,
      };
    },
    Remove: (state, params: PayloadAction<{ name: string }>) => {
      const { payload } = params;
      delete state[payload.name];
    },
    Clear: (state, params: PayloadAction<{ name: string }>) => {
      const { name } = params.payload;
      state[name] = {
        ...state[name],
        status: "idle",
        error: false,
        message: null,
      };
    },
    UpdateToError: (
      state,
      params: PayloadAction<{
        name: string;
        message: {
          severity: "info" | "warning" | "error" | "success";
          message: string | null;
        };
      }>
    ) => {
      const { name, message } = params.payload;
      state[name] = {
        status: "idle",
        error: true,
        message: message,
      };
    },
  },
  extraReducers: (builder) => {
    APIStateAsyncThunks(builder);
    APIStateCart(builder);
    APIStateContactPerson(builder);
    APIStateMenu(builder);
    APIStateReport(builder);
  },
});

export const APIState = (state: RootState) => state.APIStates;

export default APISlice.reducer;
