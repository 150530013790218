import axios, { AxiosRequestConfig } from "axios";
import packageJson from "../package.json";
import { APIResponse, ErrorResponse } from "./models";
import { getSettings } from "./auth";
import { User } from "oidc-client-ts";

const getUser = async () => {
  const { authority, client_id, userStore } = getSettings();
  return await userStore?.get(`user:${ authority }:${ client_id }`).then(oidcStorage => {
    if (!oidcStorage) {
      return null;
    }

    return User.fromStorageString(oidcStorage);
  });
};

const getToken = async () => {
  const user = await getUser();
  return user?.access_token ?? "";
};

export const getUserAccessToken = getToken;

const instance = axios.create({
  // Value set in git workflow file during build phase
  baseURL: process.env.REACT_APP_BASE_URL ?? "https://pos.apron-strings.co.za/api",
  timeout: 1000 * 45, // 45 seconds
});

instance.interceptors.response.use(
  (response) => {
    if (
      ( response.data as ErrorResponse )?.success === false &&
      ( response.data as ErrorResponse )?.message
    ) {
      return Promise.reject({
        code: response.status,
        message: response.data.message,
      });
    } else {
      return Promise.resolve({
        data: response.data,
        headers: response.headers,
      });
    }
  },
  (error) =>
    Promise.reject({
      code: error?.response?.status?.toString() ?? "500",
      message: error.response.data.message ?? error.response.statusText,
    })
);

instance.interceptors.request.use(
  async (requestConfig: AxiosRequestConfig) => {
    if (!requestConfig.data) {
      requestConfig["data"] = {};
    }
    requestConfig.data["AppV"] = packageJson.version;

    // @ts-ignore
    requestConfig.headers["Authorization"] = `Bearer ${ await getToken() }`;
    return requestConfig;
  },
  (error) => Promise.reject(error)
);

export const PostWrapper = <T>(url: string, data: any, config?: any) =>
  new Promise<APIResponse<T>>((resolve, reject) => {
    instance
      .post<APIResponse<T>>(url, data, config)
      .then((response) => {
        // @ts-ignore
        const { success, message, data, meta } = response.data;
        resolve({
          data: data,
          metaData: {
            success: success,
            message: message,
            meta: meta,
          },
          headers: response.headers,
        });
      })
      .catch((error) => {
        reject({
          code: error.code,
          message: error.message,
        });
      });
  });

export const PutWrapper = <T>(url: string, data?: any, config?: any) =>
  new Promise<APIResponse<T>>((resolve, reject) => {
    instance
      .put<APIResponse<T>>(url, data, config)
      .then((response) => {
        // @ts-ignore
        const { success, message, data, meta } = response.data;
        resolve({
          data: data,
          metaData: {
            success: success,
            message: message,
            meta: meta,
          },
          headers: response.headers,
        });
      })
      .catch((error) => {
        reject({
          code: error.code,
          message: error.message,
        });
      });
  });

export const GetWrapper = <T>(url: string, config?: AxiosRequestConfig) =>
  new Promise<APIResponse<T>>((resolve, reject) => {
    instance
      .get<APIResponse<T>>(url, config)
      .then((response) => {
        // @ts-ignore
        const { success, message, data, meta } = response.data;
        resolve({
          data: data,
          metaData: {
            success: success,
            message: message,
            meta: meta,
          },
          headers: response.headers,
        });
      })
      .catch((error) => {
        reject({
          code: error.code,
          message: error.message,
        });
      });
  });

export const DeleteWrapper = <T>(url: string, config?: AxiosRequestConfig) =>
  new Promise<APIResponse<T>>((resolve, reject) => {
    instance
      .delete<APIResponse<T>>(url, config)
      .then((response) => {
        // @ts-ignore
        const { success, message, data, meta } = response.data;
        resolve({
          data: data,
          metaData: {
            success: success,
            message: message,
            meta: meta,
          },
          headers: response.headers,
        });
      })
      .catch((error) => {
        reject({
          code: error.code,
          message: error.message,
        });
      });
  });

export default instance;
