import React, { ReactNode, useEffect, useState } from "react";
import MetaContextProvider, {
  IF_MetaContext,
} from "./MetaContextProvider/MetaContextProvider";

interface IF_ContextProvider {
  children: ReactNode;
}

const ContextProvider = ({ children }: IF_ContextProvider) => {
  const [meta, setMeta] = useState<IF_MetaContext | null>(null);

  const EnableDebuggingData = () => {
    // @ts-ignore
    setMeta({ ...meta, debugData: true });
    console.log("Debug Mode Has Been Activated.");
  };

  const _global = (window || global) as any;
  _global.EnableDebuggingData = EnableDebuggingData;

  useEffect(() => {
    fetch("/meta.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((data) => data.json())
      .then((data) => setMeta(data))
      .catch((err) => {
        console.error(err);
        setMeta({ version: "unknown", debugData: false });
      });
  }, []);

  return (
    <MetaContextProvider.Provider value={meta}>
      {children}
    </MetaContextProvider.Provider>
  );
};

export default ContextProvider;
