import { createContext } from "react";

export interface IF_MetaContext {
  version: string;
  debugData: boolean;
}

const MetaContext = createContext<IF_MetaContext | null>(null);

export default MetaContext;
