import React from "react";
import Logo from "../../UI/Logo/Logo";
import { Button, Grid, Paper } from "@mui/material";
import classes from "./LoginForm.module.css";
import { useAuth } from "react-oidc-context";

interface ILoginForm {
  redirectURL?: string;
}

const LoginForm = ({ redirectURL }: ILoginForm) => {

  const auth = useAuth();

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 }>
        <div className={ "TextCenter" }>
          <Logo className={ classes.Logo } />
        </div>
      </Grid>
      <Grid item xs={ 12 } textAlign={ "center" } alignItems={ "center" }>
        <Paper
          sx={ (theme) => ( {
            maxWidth: { xs: "100%", md: theme.breakpoints.values.md },
            borderRadius: "0.5rem",
            margin: "auto",
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2)
          } ) }
        >
          <Button variant={ "contained" } onClick={ () => auth.signinRedirect() }>
            Sign In With Keycloak
          </Button>
        </Paper>
      </Grid>

    </Grid>
  );
};

export default ( LoginForm );
