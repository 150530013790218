import React, { Fragment, useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Drawer,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import NavigationItems from "../../components/Navigation/NavigationItems/NavigationItems";
import { ILinkInterface } from "../../models";
import Logo from "../../components/UI/Logo/Logo";
import classes from "./Layout.module.css";
import { useTheme } from "@mui/material/styles";
import Clock from "../../components/UI/Clock/Clock";

interface IF_Layout {
  children: React.ReactNode;
  links: Array<ILinkInterface>;
}

const Layout = ({ children, links }: IF_Layout) => {
  const [showSideDrawer, setShowSideDrawer] = useState(false);

  const theme = useTheme();
  const lessThanMD = useMediaQuery(theme.breakpoints.down("md"));

  const sideDrawerClosedHandler = () => {
    setShowSideDrawer(false);
  };

  const sideDrawerToggleHandler = () => {
    setShowSideDrawer(!showSideDrawer);
  };

  const SideBarPopulate = (orientation: "vertical" | "horizontal") => {
    return (
      <Container
        maxWidth={ "xl" }
        sx={ (theme) => ( {
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          padding: theme.spacing(1),
          paddingTop: { xs: theme.spacing(10), md: theme.spacing(1) },
          height: "100%",
          justifyContent: { xs: "flex-start", md: "space-between" },
        } ) }
      >
        <Box className={ classes.LogoHolder }>
          <Logo className={ classes.Logo } />
        </Box>
        { lessThanMD ? null :
          <Box>
            <Clock />
          </Box>
        }
        <Box
          sx={ {
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
          } }
        >
          <NavigationItems
            items={ links }
            orientation={ orientation }
            closeSideDrawer={ sideDrawerClosedHandler }
          />
        </Box>
      </Container>
    );
  };

  return (
    <Fragment>
      <AppBar
        position={ "static" }
        color={ "primary" }
        sx={ { zIndex: (theme) => theme.zIndex.drawer + 1 } }
      >
        <Toolbar sx={ { display: { xs: "none", md: "initial" }, backgroundColor: "#badee9" } }>
          { SideBarPopulate("horizontal") }
        </Toolbar>
        <Toolbar sx={ { display: { xs: "initial", md: "none" }, backgroundColor: "#badee9" } }>
          <IconButton
            size={ "large" }
            edge={ "start" }
            color={ "inherit" }
            sx={ { mr: 2, display: { xs: "initial", md: "none" } } }
            onClick={ sideDrawerToggleHandler }
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <Drawer open={ showSideDrawer } onClose={ sideDrawerClosedHandler }>
          { SideBarPopulate("vertical") }
        </Drawer>
      </AppBar>
      <ErrorBoundary>
        <Container
          maxWidth={ false }
          component={ "main" }
          disableGutters={ lessThanMD }
          sx={ {
            display: "flex",
            flexFlow: "column",
            flex: "1 0 auto",
            height: "calc(100vh - 100px)",
            paddingTop: theme => theme.spacing(1)
          } }
        >
          { children }
        </Container>
      </ErrorBoundary>
    </Fragment>
  );
};

export default Layout;
