import React, { useEffect, useState } from "react";
import { Button, Tab, Tabs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { ILinkInterface } from "../../../models";
import ChildMenu from "./ChildMenuItems";
import { useAuth } from "react-oidc-context";

interface IF_NavigationItems {
  closeSideDrawer: () => void;
  items: Array<ILinkInterface>;
  navLogo?: string | React.ReactElement;
  orientation: "vertical" | "horizontal";
  loadNavigation?: boolean;
}

interface anchorEl {
  childIndex: number;
  element: null | HTMLElement;
  open: boolean;
  link: ILinkInterface;
}

const pathSearch: (
  links: Array<ILinkInterface>,
  location: string
) => number = (links, location) =>
  links
    .map((link, index) => {
      const ret = [];
      if (link.children) {
        ret.push(
          ...link.children.map((l, i) => ( { link: l.link, index: index } ))
        );
      }
      ret.push({ link: link.link, index: index });
      return ret;
    })
    .reduce((acc, curr) => acc.concat(curr))
    .find((link) => location.includes(link.link) && link.link !== "/")?.index ?? 0;

const NavigationItems = ({
                           items,
                           closeSideDrawer,
                           orientation,
                           loadNavigation = true,
                         }: IF_NavigationItems) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState<Array<anchorEl>>([]);

  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    if (loadNavigation) {
      if (location.pathname !== "/") {
        setTabIndex(pathSearch(items, location.pathname));
      }
    }
  }, [location, items, loadNavigation]);

  useEffect(() => {
    const anchorEls: Array<anchorEl> = [];
    let childIndex = 0;
    items.forEach((link) => {
      if (link.children) {
        anchorEls.push({
          childIndex: childIndex++,
          element: null,
          open: false,
          link: link,
        });
      }
    });
    setAnchorEl(anchorEls);
    return () => {
      setAnchorEl([]);
    };
  }, [items]);

  const handleTabChange = (event: React.SyntheticEvent, newTabIndex: any) => {
    setTabIndex(newTabIndex);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    linkValue: string
  ) => {
    let tempIndex = anchorEl.findIndex((x) => x.link.value === linkValue);
    if (tempIndex > -1) {
      const temp = anchorEl[tempIndex];
      temp.element = event.currentTarget;
      temp.open = true;
      anchorEl[tempIndex] = temp;
      setAnchorEl([...anchorEl]);
    }
  };

  const handleClose = (index: number) => {
    let temp = anchorEl[index];
    if (temp) {
      temp.open = false;
      anchorEl[index] = temp;
      setAnchorEl([...anchorEl]);
    }
  };

  const getChildMenuItems = () => {
    if (anchorEl.length > 0) {
      return anchorEl.map((x, index) => {
        return (
          <ChildMenu
            anchorEl={ x.element }
            key={ `${ index }_childMenu` }
            closeSideDrawer={ closeSideDrawer }
            link={ x.link }
            open={ x.open }
            handleClose={ () => handleClose(index) }
          />
        );
      });
    }
  };

  return (
    <React.Fragment>
      <Tabs
        textColor={ "inherit" }
        onChange={ handleTabChange }
        orientation={ orientation }
        sx={ {
          alignItems: "center",
        } }
        value={ tabIndex }
        variant={ "scrollable" }
        scrollButtons={ "auto" }
      >

        { items.map((link, index) => {
          index += 1;
          if (link.children) {
            return (
              <Tab
                { ...link.additionalProps }
                sx={ { opacity: 1 } }
                key={ `${ link.link }link_children${ index }` }
                label={ link.value }
                component={ Button }
                onClick={ (e: React.MouseEvent<HTMLButtonElement>) => {
                  handleClick(e, link.value);
                  handleTabChange(e, index - 1);
                } }
              />
            );
          } else {
            return (
              <Tab
                { ...link.additionalProps }
                onClickCapture={ (e: any) => {
                  closeSideDrawer();
                } }
                /*tabIndex={index}*/
                label={ link.value }
                to={ link.internal ? link.link : undefined }
                href={ !link.internal ? link.link : undefined }
                component={ link.internal ? Link : "a" }
                key={ `${ link.link }` }
                sx={ (theme) => ( {
                  opacity: 1,
                  display: "inline-flex",
                  marginX: { md: theme.spacing(1.5) },
                  marginY: { xs: theme.spacing(1.5), md: "initial" },
                } ) }
              />
            );
          }
        }) ?? null }
        { auth.isAuthenticated && <Tab onClick={ () => auth.signoutRedirect() } label={ "Logout" } sx={ (theme) => ( {
          opacity: 1,
          display: "inline-flex",
          marginX: { md: theme.spacing(1.5) },
          marginY: { xs: theme.spacing(1.5), md: "initial" },
        } ) } /> }
      </Tabs>
      { getChildMenuItems() }
    </React.Fragment>
  );
};

export default NavigationItems;
