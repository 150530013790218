const mainBackgroundBlue = "#19c5fa";
const peach = "#e9c5ba";
const brightRed = "#F1392AFF";
export const getTheme = () => {
  return {
    palette: {
      mode: "light",
      primary: {
        main: mainBackgroundBlue, contrastText: "#000000"

      },
      secondary: {
        main: peach, contrastText: "#000000"
      },
      error: {
        main: brightRed,
      },
      text: {
        primary: "#000000"
      }
    },
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
              paddingLeft: "30px",
            },
          },
        },
      }, MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
              paddingLeft: "30px",
            },
          },
        },
      },
    }
  };
};
